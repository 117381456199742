@import url(https://fonts.googleapis.com/css?family=PT+Serif|Source+Sans+Pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(148, 148, 148, 0.308);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(0, 0, 0, 0.871);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8c8c8c;
}

/*◇─◇──◇────◇────◇──── GLOBAL ────◇────◇────◇──◇─◇*/
h1, h2, h3, h4, h5 {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: normal !important;
  font-optical-sizing: auto;
}

h1 {
  font-size: calc(2em + 2vw);
}

h3 {
  font-size: calc(15pt + .5vw);
}

p {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
}

.white-text {
  color: white;
}

.sub-text {
  color: #909090;
}

.main-text {
  color: #121212;
}

hr {
  color: #BBBBBB;
}

.hr-white {
  border-color: white !important;
}

.b-0 {
  border: none !important;
}

.thicc {
  width: 20%;
  border-width: 3px;
  border-color: #232323;
  float: left;
  position: relative;
}

.thicc-sub {
  color: #979797;
  width: 45%;
  border-width: thick;
}

.btn-primary {
  color: #fff;
  background: linear-gradient(to left, #1352a1 50%, #007bff 50%);
  border-color: #1352a1;
  border-radius: 2px;
  width: -webkit-fill-available;
  background-size: 200% 104%;
  background-position: right bottom;
  transition: all .3s ease-out;
}

.btn-primary:hover {
  background-position: left bottom;
}

.btn-on-white {
  color: #fff;
  border-color: #232323;
  border-radius: 3px;
  background: linear-gradient(to left, #232323 50%, #a1a1a1 50%);
  background-size: 200% 104%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.btn-on-white:hover {
  background-position: left bottom;
  color: #fff;
  border-color: #8a8a8a;
}

.btn-wrap-right {
  float: right;
}

.Container {
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin: auto;
}

.row {
  max-width: 100vw;
}

.centered-content {
  display: flex;
  justify-content: center;
}

/*◇─◇──◇────◇────◇──── ANIMATIONS ────◇────◇────◇──◇─◇*/
.hover:hover {
  color: #CACACA;
  transform: scale(1.2);
  transition: ease-in-out .2s;
}

.fade:hover {
  transition: ease-in-out .2s;
}

.ease {
  background-color: white;
  transition: .3s ease;
}

.btn-primary:hover .ease {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border: 0;
}

/*◇─◇──◇────◇────◇──── NAVIGATION ────◇────◇────◇──◇─◇*/
.nav-left-text, .nav-left-text:hover, .nav-left-text:active {
  color: white;
  text-decoration: none !important;
}

.brand {
  font-size: 1em;
}

nav {
  width: 75vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 118px;
  height: 6vw;
}

.Nav {
  width: -webkit-fill-available;
  background: linear-gradient(#121212, #232323);
  color: white;
  min-height: 118px;
  height: 6vw;
}

.nav-links {
  text-align: start;
  list-style: none;
  color: #4F4F4F;
  align-items: end;
  float: left;
  align-content: end;
  direction: ltr;
  margin-right: 0;
  margin-left: auto;
}

.nav-link:hover {
  transition: ease-in-out .5s;
  color: #CACACA;
}

.active {
  color: white !important;
  text-decoration: underline !important;
}

.active-mobile {
  color: #232323;
  text-decoration: underline !important;
}

#logo {
  width: calc(2em + .5vw);
}

#logo-mini {
  width: 40px;
}

.nav-link {
  text-decoration: none;
  padding: 1em;
  color: #4F4F4F;
  text-decoration: none;
}

.nav-left {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: normal !important;
  font-size: calc(42px + .4vw);
  text-align: center;
  display: flex;
}

.nav-right {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: calc(1.2rem);
  display: contents;
}

.nav-right-mob, .nav-links-mob {
  font-size: 2rem;
  color: white;
  display: flex;
}

.dropdown-menu {
  right: -12.4vw;
  left: auto;
  top: 84px;
  width: 100vw;
  padding: 0;
  margin: 5px;
  border-radius: 0%;
  background: #181818;
}

.drop {
  transform: translateY(0);
  transition: transform .25s ease-out;
}

.dropdown-item:hover {
  transition: ease-in-out .2s;
  background: #121212;
}

.dropdown-toggle {
  background: transparent !important;
  border: 0px !important;
  box-shadow: transparent !important;
  font-size: 2rem;
}

.dropdown-toggle:active, .dropdown-toggle:focus, .dropdown-toggle:hover, .dropdown-toggle:visited {
  background: transparent;
  box-shadow: 0px 0px 0px transparent !important;
  border: 0px;
}

.dropdown-toggle::after {
  border: 0;
}

/*◇─◇──◇────◇────◇──── HEADER ────◇────◇────◇──◇─◇*/
.header {
  margin-bottom: 30px;
  margin-top: -3px !important;
}

.header-text {
  text-shadow: 2px 1.5px 2.5px rgba(0, 0, 0, 0.21);
}

/*◇─◇──◇────◇────◇──── HEADERS ────◇────◇────◇──◇─◇*/
.header {
  mask-image: url(/static/media/header-img.e280cf23.svg);
  -webkit-mask-image: url(/static/media/header-img.e280cf23.svg);
  -webkit-mask-size: cover;
          mask-size: cover;
  background-size: cover;
  max-width: 100vw;
  width: -webkit-fill-available;
  display: flex;
  min-height: 41vw;
  margin-top: -1px;
}

.home {
  background-image: url(/static/media/storefront5.d73e2153.png);
}

.home #logo {
  width: calc(5.5em + 20vw);
  padding-bottom: 1em;
}

.contact {
  background-image: url(/static/media/lll.ce7eb54a.png);
}

.gallery {
  background-image: url(/static/media/3.6d346770.png);
}

.tarieven {
  background-image: url(/static/media/tarieven.661833d4.png);
}

.header-inner {
  text-align: center;
  color: white;
  margin: auto;
}

/*◇─◇──◇────◇────◇──── HOME ────◇────◇────◇──◇─◇*/
.home-icons {
  display: flex;
  text-align: center;
}

.home-services {
  display: -ms-grid;
  display: grid;
  background: #1e1e1e;
  background-size: cover;
  box-shadow: inset 0 0px 13px 1px #363535ad;
}

.service p {
  margin-bottom: 2px;
  line-height: normal;
  font-size: calc(16pt + 0.2vw);
}

.service hr {
  width: 70%;
  margin-top: 2px;
  margin-bottom: 2em;
}

#wrap-1 {
  padding-left: 0px;
  padding-right: 0px;
}

#wrap-1 p {
  position: relative;
  vertical-align: middle;
  display: table-cell;
  font-size: 30pt;
}

#wrap-1:hover .overlay {
  opacity: 1;
}

#wrap-1 .overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  display: table;
  transition: .5s ease;
  text-align: center;
  position: absolute;
  color: #fff;
  background-color: rgba(53, 53, 53, 0.75);
}

#wrap-1 .overlay p {
  padding: 1rem !important;
}

.ad .jumbotron {
  display: flow-root;
}

.ad img {
  width: calc(50px + 3vw);
}

/*◇─◇──◇────◇────◇──── CONTACT ────◇────◇────◇──◇─◇*/
.timetable {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.timetable table td {
  border-top: 0px;
}

#map {
  width: 100vw;
  width: -webkit-fill-available;
  height: 25em;
}

.contact-inner {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.contact-inner img {
  width: 40px;
  height: 100%;
}

.contact-inner .sub {
  display: flex;
  justify-content: space-around;
  width: 70%;
}

.contact-inner ul {
  list-style: none;
  line-height: 4.5em;
}

.info a {
  color: #232323;
}

/*◇─◇──◇────◇────◇──── TARIEVEN ────◇────◇────◇──◇─◇*/
.price-table .row {
  text-align: center;
  font-size: calc(11px + .2vw);
}

.price-table h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: calc(19pt + .4vw);
  font-weight: bolder !important;
}

.price-table td {
  vertical-align: middle;
}

/*◇─◇──◇────◇────◇──── GALLERY ────◇────◇────◇──◇─◇*/
.top-carousel .carousel-control-prev, .top-carousel .carousel-control-next {
  display: none;
}

.bottom-carousel {
  background: #474747;
}

.bottom-carousel .izoWov > img, .kFujKX > img {
  height: 20vw !important;
  width: auto !important;
  margin: 0 !important;
  padding: 30px;
  display: block;
}

.kFujKX {
  width: auto !important;
}

.euKzJn {
  position: absolute;
  color: white;
  height: 100%;
  width: 73px;
  font-size: xx-large;
  cursor: pointer;
  font-size: 4vw;
  font-weight: bold;
}

/*◇─◇──◇────◇────◇──── FOOTER ────◇────◇────◇──◇─◇*/
.Footer {
  display: flex;
  background: #232323;
}

.footer-inner {
  width: 72vw;
  max-width: 73em;
}

.footer-list {
  list-style: none;
  padding-left: 0;
  line-height: 2em;
  margin-left: auto !important;
  margin-right: auto !important;
  display: inline-block;
}

.footer-list a {
  color: #989898 !important;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.footer-list li a :hover {
  color: white !important;
  transition: ease-in-out .2s;
}

.footer-item {
  border-left: #505050 1px solid;
  color: #989898;
}

.footer-row {
  justify-content: space-around;
  font-family: "PT Sans Narrow", sans-serif;
}

.footer-icon {
  vertical-align: middle;
  height: 17px;
}

.footer-text {
  font-size: calc(.7em + .3vw);
}

.blogo {
  display: flex;
  justify-content: center;
}
/*# sourceMappingURL=App.css.map */
